@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Red Hat Text, system-ui, sans-serif;
    }

    .icon-red {
        filter: invert(49%) sepia(96%) saturate(1352%) hue-rotate(310deg) brightness(100%) contrast(98%);
    }
}